<template>
    <OMediaQueryProvider>
        <OMatchMedia v-slot="{ isMobile }">
            <template v-if="isMobile">
                <MPill color="primary" v-model="model">
                    {{ text }}
                </MPill>
            </template>
            <template v-else>
                <div class="form-check">
                    <input class="form-check-input" :id="id" type="checkbox" value="" v-model="model">
                    <label class="form-check-label" :for="id">
                        {{ text }}
                    </label>
                </div>
            </template>
        </OMatchMedia>
    </OMediaQueryProvider>
</template>

<script setup>
    import { computed } from "vue";
    import OMatchMedia from 'o365.vue.components.MatchMedia.vue';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import MPill from "mobile.vue.components.Pill.vue";

    const props = defineProps({
        type: { type: String, default: "checkbox" },
        text: { type: String, required: true },
        modelValue: {},
    });
    const emit = defineEmits(["update:modelValue"]);

    const model = computed({
        get: () => props.modelValue,
        set: (val) => emit("update:modelValue", val),
    });

    const id = crypto.randomUUID();
</script>
